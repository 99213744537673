import React from "react"
import { sanityConfig } from "../../sanityConfig"
import GatsbyImage from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

export const BlockLink = ({
  url = "#",
  content = "Sample Content",
  className = "",
  newWindow = false,
}) => {
  return (
    <a
      href={url}
      target={`${newWindow ? "_blank" : "_self"}`}
      rel={`${newWindow ? "noopener noreferrer" : ""}`}
      className={` break-all underline font-bold hover:text-fifth ${className}`}
    >
      {content}
    </a>
  )
}

export const BlockContentFigure = ({ imageAssetId, alt = "" }) => {
  const fluidProps = getFluidGatsbyImage(
    imageAssetId,
    { maxWidth: 600 },
    sanityConfig
  )

  return <GatsbyImage fluid={fluidProps} alt={alt} />
}
