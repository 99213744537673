import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import urlBuilder from "@sanity/image-url"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import Img from "gatsby-image"

import { sanityConfig } from "../../sanityConfig"
import { BlockContentFigure, BlockLink } from "./BlockContentComponents"

const InternalLink = ({ linkProps, preSlug }) => (
  <span>
    <Link
      to={`/${preSlug}/${linkProps.mark.item.slug.current}`}
      className="underline hover:text-blue-dark visited:text-purple-600"
    >
      {linkProps.children}
    </Link>
  </span>
)

const BlockContentPrimary = ({ blockData }) => {
  //   Serializer is used to tell component how to render Portable Text
  //   if no serializer is used, a default serializer is used under-the hood
  //   This allows us to customize how modify defaults and render custom formats
  const serializer = {
    marks: {
      link: (props) => {
        // BlockLink has logic for new window if props.mark.blank is valid
        return (
          <BlockLink
            url={props.mark.href}
            newWindow={props.mark.blank}
            content={props.children}
          />
        )
      },
      internalPostLink: (props) => {
        return <InternalLink linkProps={props} preSlug="pages" />
      },
      linkToBlogPost: (props) => {
        return <InternalLink linkProps={props} preSlug="news" />
      },
      LinkToCommunityDept: (props) => {
        let preSlug = "communities"
        if (props.mark.type === "individualCommunityPages") {
          preSlug = "departments"
        }

        return <InternalLink linkProps={props} preSlug={preSlug} />
      },
    },
    types: {
      inlineImage: (props) => {
        const sanityImageId = props.node.asset.id
        const alt = props?.node?.alt
        // const fluidProps = getFluidGatsbyImage(
        //   sanityImageId,
        //   { maxWidth: 800 },
        //   sanityConfig
        // )
        return (
          <>
            <figure className="grid w-full justify-items-center my-10  ">
              <div className="w-full max-w-xl">
                <BlockContentFigure imageAssetId={sanityImageId} alt={alt} />
              </div>

              {props.node.caption && (
                <figcaption className="font-bold text-2xl text-center mt-3 font-heading">
                  {props.node.caption}
                </figcaption>
              )}
            </figure>
          </>
        )
      },
      block: (props) => {
        const { style = "normal" } = props.node

        // if (/^h\d/.test(style)) {
        //   const level = style.replace(/[^\d]/g, "")
        //   return React.createElement(
        //     style,
        //     { className: `heading-${level}` },
        //     props.children
        //   )
        // }

        if (style === "normal") {
          return <p className="text-lg mb-5">{props.children}</p>
        }

        if (style === "h1") {
          return (
            <h1 className=" text-3xl sm:text-4xl md:text-5xl my-10  text-center  ">
              {props.children}
            </h1>
          )
        }

        if (style === "h2") {
          return <h2 className="text-3xl mt-5 mb-3">{props.children}</h2>
        }

        if (style === "h3") {
          return <h3 className="text-2xl mt-5 mb-3">{props.children}</h3>
        }

        if (style === "h4") {
          return <h4 className="text-xl mt-5 mb-3">{props.children}</h4>
        }

        if (style === "blockquote") {
          return (
            <div className="grid">
              <blockquote className="italic border-l-4 border-fifth bg-primary bg-opacity-10 p-2 my-10 justify-self-center text-lg mt-8 mb-3 w-10/12 md:w-3/4">
                {props.children}
              </blockquote>
            </div>
          )
        }

        // Fall back to default handling
        return PortableText.defaultSerializers.types.block(props)
      },
    },
    list: (props) =>
      props.type === "bullet" ? (
        <ul className="mt-4">{props.children}</ul>
      ) : (
        <ol className="mt-4">{props.children}</ol>
      ),
  }

  return <PortableText blocks={blockData} serializers={serializer} />
}

export default BlockContentPrimary
